.modaluser_root {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  background-color: rgba(0, 0, 0, 0.66);
}
.modaluser_wrap {
  width: 360px;
  max-width: 360px;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 4px;
  padding: 32px 40px;
  margin: 120px auto 60px;
  position: relative;
  outline: none;
}
.modaluser_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
  background-color: rgba(0, 0, 0, 0.72);
}
.modaluser_title {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.modaluser_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: white;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-left: 12px;
}
.modaluser_forms {
  padding: 8px 0;
}
.form_group {
  box-sizing: inherit;
}
.form_field_user {
  margin-bottom: 12px;
  min-height: 76px;
  position: relative;
}
.input_label {
  display: flex;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  color: white;
  margin-bottom: 8px;
}
.input_field_box {
  box-sizing: inherit;
}
.input_field {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 93%;
  color: #333;
  font-size: 14px;
  margin-bottom: 4px;
  height: 32px;
  padding: 0 8px;
  outline: 0;
}
.pass_input {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.modal_login_button {
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: #1e4904;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  padding: 0 12px;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
}

.modal_frameup_u {
  position: absolute;
  height: 12px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
}
.modal_framedown_u {
  position: absolute;
  height: 12px;
  width: 100%;
  left: 0;
  top: 98%;
  z-index: 6;
}
.modal_frameleft_u {
  position: absolute;
  width: 12px;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 6;
}
.modal_frameright_u {
  position: absolute;
  width: 12px;
  top: 0;
  left: 97.5%;
  height: 100%;
  z-index: 6;
}
.corder_left_down_u {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 97%;
  z-index: 6;
}
.corder_left_up_u {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 0;
  z-index: 6;
}
.corder_right_down_u {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 97%;
  left: 97%;
  z-index: 6;
}
.corder_right_up_u {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 0;
  left: 97%;
  z-index: 6;
}

@media all and (max-width: 1200px) {
  .modaluser_wrap {
    width: 360px;
    max-width: 360px;
    background-color: rgba(0, 0, 0, 0.72);
    border-radius: 4px;
    padding: 32px 40px;
    margin-left: 21.6%;
    margin-top: 6px;
    position: relative;
    outline: none;
  }
  .modal_hist_wrap {
    width: 420px;
    background-color: rgba(0, 0, 0, 0.9);
    max-width: 420px;
    max-height: 290px;
    border-radius: 4px;
    margin-left: 18%;
    padding: 32px 40px;
    margin-top: 3px;
    position: relative;
    border: none;
    outline: none;
    color: white;
  }
  .history_list {
    max-height: 270px;
  }
  .modal_framedown,
  .corder_left_down,
  .corder_right_down {
    display: none;
  }
}
