.splash_modal {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  /* background-color: rgba(0, 0, 0, 0.48); */
}
.splash_container {
  width: 100%;
  max-width: 100%;
  height: 88%;
  border-radius: 4px;
  position: relative;
  border: none;
  outline: none;
  margin-top: 5% !important;
  display: flex;
}
.splash_left_opp,
.splash_right_opp {
  width: 50%;
  height: 100%;
}
.splash_after_root {
  display: block;
  width: 100%;
  height: 100%;
}
.end_message {
  position: absolute;
  top: 5%;
  /* left: -197px; */
  left: 30%;
}
.end_message_lost {
  position: absolute;
  top: 5%;
  /* left: -197px; */
  left: 30%;
}
.firstdice {
  position: absolute;
  top: 340px;
  left: 42%;
}
.seconddice {
  position: absolute;
  top: 340px;
  left: 55.4%;
}
.how_much_to_win {
  position: absolute;
  color: darkgreen;
  width: 100%;
  height: 5.55%;
  top: 460px;
  left: 0;
  font-size: 2.7vw;
  font-weight: 900;
  text-align: center;
}
.versus_splash {
  position: absolute;
  width: 180px;
  height: 180px;
  left: 46%;
  top: 35%;
  animation: anim 800ms 1;
}
.your_throw_details,
.opp_throw_details {
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  top: 15%;
  left: 75%;
  color: ghostwhite;
  width: 160px !important;
  height: 200px;
  text-align: center;
}
.opp_throw_details {
  left: 15%;
}
.your_throw_abs_dice,
.your_throw_abs_dice2,
.opp_throw_abs_dice,
.opp_throw_abs_dice2 {
  position: absolute;
  top: 21%;
  left: 75%;
  width: 60px;
  height: 60px;
}
.your_throw_abs_dice2 {
  left: 80%;
}
.opp_throw_abs_dice {
  left: 15%;
}
.opp_throw_abs_dice2 {
  left: 20%;
}
@keyframes anim {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.5) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@media all and (max-width: 1280px) and (orientation: landscape) {
  .versus_splash {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 45%;
    top: 25%;
    animation: anim 800ms 1;
  }
  .gif_gif1,
  .gif_gif2 {
    width: 90%;
    height: 100%;
  }
  .your_throw_details,
  .opp_throw_details {
    position: absolute;
    font-size: 0.72rem;
    font-weight: 500;
    top: 15%;
    left: 75%;
    color: ghostwhite;
    width: 160px !important;
    height: 200px;
    text-align: center;
  }
  .opp_throw_details {
    left: 6%;
  }
  .your_throw_abs_dice,
  .your_throw_abs_dice2,
  .opp_throw_abs_dice,
  .opp_throw_abs_dice2 {
    height: 30px;
    width: 30px;
  }
  .your_throw_abs_dice {
    left: 81%;
  }
  .your_throw_abs_dice2 {
    left: 87%;
  }
  .opp_throw_abs_dice {
    left: 12%;
  }
  .opp_throw_abs_dice2 {
    left: 18%;
  }
  .end_message,
  .end_message_lost {
    position: absolute;
    top: 5%;
    left: 30%;
    width: 300px;
    height: 240px;
  }
  .firstdice {
    position: absolute;
    top: 130px;
    left: 42%;
    width: 20px;
    height: 20px;
  }
  .seconddice {
    position: absolute;
    top: 130px;
    left: 55.5%;
    width: 20px;
    height: 20px;
  }
  .how_much_to_win {
    position: absolute;
    color: darkgreen;
    width: 13.636%;
    height: 5.55%;
    top: 175px;
    left: 43.5%;
    font-size: 2.7vw;
    font-weight: 900;
    text-align: center;
  }
}

@media all and ((max-width: 668px) and (orientation: landscape)) {
  .how_much_to_win {
    left: 45.5%;
  }
  .firstdice {
    left: 44%;
  }
  .seconddice {
    left: 58%;
  }
  .your_throw_details {
    left: 74%;
  }
  .opp_throw_details {
    left: 5%;
  }
}
@media all and ((min-width: 669px) and (max-width: 918px) and (orientation: landscape)) {
  .how_much_to_win {
    left: 45.5%;
  }
  .firstdice {
    left: 45%;
  }
  .seconddice {
    left: 55.3%;
  }
  .your_throw_details {
    left: 77%;
  }
  .opp_throw_details {
    left: 8%;
  }
  .your_throw_abs_dice,
  .your_throw_abs_dice2,
  .opp_throw_abs_dice,
  .opp_throw_abs_dice2 {
    height: 36px;
    width: 36px;
  }
  .end_message,
  .end_message_lost {
    position: absolute;
    top: 5%;
    left: 35%;
    width: 300px;
    height: 240px;
  }
  .how_much_to_win {
    left: 44.5%;
  }
}
