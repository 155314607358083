.splash_modal {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  /* background-color: rgba(0, 0, 0, 0.48); */
}
.modal_container {
  width: 30.3%;
  max-width: 30.3%;
  height: 88%;
  border-radius: 4px;
  position: relative;
  border: none;
  outline: none;
  margin-left: 30%;
  margin-top: 5% !important;
}
.modal_container_res {
  width: 30.3%;
  max-width: 30.3%;
  height: 100%;
  border-radius: 4px;
  position: relative;
  border: none;
  outline: none;
  margin-left: 39.85%;
}
.gif_gif {
  width: 100%;
  height: 100%;
}
.splash_after_root {
  display: block;
  width: 100%;
  height: 100%;
}
.end_message {
  position: absolute;
  top: 5%;
  left: -197px;
}
.end_message_lost {
  position: absolute;
  top: 5%;
  left: -197px;
}
.firstdice {
  position: absolute;
  top: 340px;
  left: 33px;
}
.seconddice {
  position: absolute;
  top: 340px;
  left: 287px;
}
.how_much_to_win {
  position: absolute;
  color: darkgreen;
  width: 65.4%;
  height: 5.55%;
  top: 480px;
  font-size: 2.7vw;
  font-weight: 900;
  text-align: center;
}
.your_throw_abs,
.opp_throw_abs {
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  top: 15%;
  left: 100%;
  color: ghostwhite;
  width: 350px !important;
  height: 200px;
  text-align: center;
}
.opp_throw_abs {
  left: -100%;
}
.your_throw_abs_dice,
.your_throw_abs_dice2,
.opp_throw_abs_dice,
.opp_throw_abs_dice2 {
  position: absolute;
  top: 21%;
  left: 115%;
  width: 60px;
  height: 60px;
}
.your_throw_abs_dice2 {
  left: 135%;
}
.opp_throw_abs_dice {
  left: -85%;
}
.opp_throw_abs_dice2 {
  left: -65%;
}
@media all and (max-width: 1280px) and (orientation: landscape) {
  .end_message,
  .end_message_lost {
    position: absolute;
    top: 5%;
    left: -60px;
    width: 300px;
    height: 240px;
  }
  .firstdice {
    position: absolute;
    top: 141px;
    left: 24px;
    width: 20px;
    height: 20px;
  }
  .seconddice {
    position: absolute;
    top: 141px;
    left: 136px;
    width: 20px;
    height: 20px;
  }
  .how_much_to_win {
    position: absolute;
    color: darkgreen;
    width: 13.636%;
    height: 5.55%;
    top: 185px;
    left: 73px;
    font-size: 2.7vw;
    font-weight: 900;
    text-align: center;
  }
  .your_throw_abs,
  .opp_throw_abs {
    font-size: 0.7rem;
    top: 15%;
    left: 110%;
    color: ghostwhite;
    width: 150px !important;
    height: 100px;
    text-align: center;
  }
  .opp_throw_abs {
    left: -102%;
  }
  .your_throw_abs_dice,
  .your_throw_abs_dice2,
  .opp_throw_abs_dice,
  .opp_throw_abs_dice2 {
    position: absolute;
    top: 21%;
    left: 125%;
    width: 30px;
    height: 30px;
  }
  .your_throw_abs_dice2 {
    left: 145%;
  }
  .opp_throw_abs_dice {
    left: -85%;
  }
  .opp_throw_abs_dice2 {
    left: -65%;
  }
}
