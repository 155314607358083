.App {
  height: 100vh;
  background: url("../public/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  position: relative;
  top: 0;
  left: 0;
}
.betzone_player {
  position: absolute;
  width: 11%;
  height: 10%;
  top: 47.5%;
  left: 65%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_pc {
  position: absolute;
  width: 11%;
  height: 10%;
  top: 47.5%;
  left: 24%;
  display: inline-block;
  border-radius: 30%;
}
.bottom_chips {
  position: fixed;
  bottom: 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.chip_container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.chip_container_selected {
  transform: translateY(-30px);
  transition: 0.3s;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}
.chip_container > span,
.chip_container_selected > span {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  color: white;
  top: -60px;
  pointer-events: none;
}
.chip_container_selected > span {
  top: -63px;
}
.chip_container > img {
  width: 81px;
  /* height: 81px; */
}
.chip_container_selected > img {
  width: 90px;
  height: 90px;
}
.white_chip > span {
  color: black;
}
.top_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.left_header,
.right_header {
  display: flex;
  width: 40%;
  padding: 1rem;
  padding-top: 0.3rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: burlywood;
}
.right_header {
  justify-content: space-between !important;
}
.right_clock {
  min-width: 150px;
  font-size: 1rem;
}
.right_icons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 3rem;
  cursor: pointer;
}
.right_icon > img {
  width: 51px;
  height: 51px;
}
.left_history {
  cursor: pointer;
}
.betzone_chips {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 46%;
  left: 73%;
}
.betzone_chips > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.betzone_chips > div {
  width: 60px;
  text-align: center;
  font-size: 0.81rem;
  font-weight: 500;
  position: relative;
  color: white;
  top: -45px;
  pointer-events: none;
}
.betzone_chips_pc {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 46%;
  left: 33%;
}
.betzone_chips_pc > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.betzone_chips_pc > div {
  width: 60px;
  text-align: center;
  font-size: 0.81rem;
  font-weight: 500;
  position: relative;
  color: white;
  top: -45px;
  pointer-events: none;
}
.white_chip {
  color: black !important;
}
.bottom_buttons {
  position: fixed;
  bottom: 1%;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.bottom_buttons > img {
  width: 90px;
  height: 90px;
}
.bottom_buttons > div {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: burlywood;
}
.button_undo,
.button_throw,
.button_double,
.button_clear {
  width: 90px;
  height: 90px;
  background: url("../public/buttons/undo.png");
  background-repeat: no-repeat;
  background-size: 90px 90px;
  cursor: pointer;
}
.button_throw {
  background: url("../public/buttons/throw.png");
}
.button_double {
  background: url("../public/buttons/double.png");
}
.button_clear {
  background: url("../public/buttons/clear.png");
}
.button_undo:hover {
  background: url("../public/buttons/undo_push.png");
}
.button_throw:hover {
  background: url("../public/buttons/throw_push.png");
}
.button_double:hover {
  background: url("../public/buttons/double_push.png");
}
.button_clear:hover {
  background: url("../public/buttons/clear_push.png");
}

.dropdown_root {
  position: absolute;
  width: 9.3%;
  top: 7.3%;
  z-index: 2;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 12px;
}
.dropdown_item {
  padding: 6px 9px;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: 9px;
  border-radius: 6px;
  z-index: 12299;
}
.dropdown_item:hover {
  cursor: pointer;
  background-color: rgba(66, 96, 39, 0.9);
}
.dropdown_res {
  font-size: 0.6vw;
}

.res_dice {
  margin-left: 2px;
  margin-bottom: -3px;
}
.modalhist_root {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  background-color: rgba(0, 0, 0, 0.66);
}
.modal_hist_wrap {
  width: 600px;
  height: 600px;
  max-width: 600px;
  border-radius: 4px;
  padding: 32px 40px;
  margin-left: 618px;
  margin-top: 108px;
  position: relative;
  border: none;
  outline: none;
  color: white;
}
.history_title {
  display: flex;
  text-align: center;
  font-size: 141%;
  font-weight: 400;
  margin-bottom: 12px;
}
.hist_lbl {
  width: 90%;
}
.history_list {
  margin-top: 6px;
  max-height: 540px;
  overflow: auto;
}
.history_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.history_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.history_list::-webkit-scrollbar-thumb {
  background-color: rgba(66, 96, 39, 0.9);
}
.history_list_titles {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
  margin-top: 3px;
}

.history_single_root {
  padding: 3px;
  cursor: pointer;
  color: gray;
  display: flex;
}
.hist_act {
  color: white;
}
.history_single_root:hover {
  color: white;
}

.label_round,
.label_date,
.label_stake,
.label_win {
  width: 25%;
  text-align: center;
}
.label_round_s {
  width: 23%;
  margin-left: 2%;
}
.label_date_s {
  width: 27%;
}
.label_stake_s {
  width: 17%;
  text-align: right;
}
.label_win_s {
  width: 25%;
  text-align: right;
}
.close_button {
  cursor: pointer;
}
.history_details_gen {
  padding: 24px;
}
.details_old_bal,
.details_new_bal {
  margin-left: 36px;
}
.details_old_bal span {
  font-size: 81%;
  margin-right: 12px;
}
.details_new_bal span {
  font-size: 81%;
  margin-right: 20px;
}
.opp_throw,
.opp_throw_single {
  margin-left: 36px;
  font-size: 81%;
}
.opp_throw span {
  margin-right: 28px;
}
.opp_throw_single span {
  margin-right: 36px;
}
.details_round_details,
.details_each_bet {
  margin-left: 36px;
  display: flex;
}
.round_det_dices {
  font-size: 81%;
  margin-top: 6px;
  width: 30%;
}
.round_det_dices span {
  margin-right: 30px;
}
.res_dice_sg {
  margin-bottom: -3px;
  margin-right: 3px;
}
.round_det_stake {
  width: 30%;
  margin-top: 6px;
}
.round_det_stake span {
  font-size: 81%;
  margin-right: 6px;
}
.modal_frameup {
  position: absolute;
  height: 12px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
}
.modal_framedown {
  position: absolute;
  height: 12px;
  width: 100%;
  left: 0;
  top: 98%;
  z-index: 6;
}
.modal_frameleft {
  position: absolute;
  width: 12px;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 6;
}
.modal_frameright {
  position: absolute;
  width: 12px;
  top: 0;
  left: 98.5%;
  height: 100%;
  z-index: 6;
}
.corder_left_down {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 97.7%;
  z-index: 6;
}
.corder_left_up {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 0;
  z-index: 6;
}
.corder_right_down {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 97.7%;
  left: 98.2%;
  z-index: 6;
}
.corder_right_up {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 0;
  left: 98.2%;
  z-index: 6;
}
.logoff_dropdown {
  position: absolute;
  width: 9.3%;
  top: 7.3%;
  left: 90.6%;
  z-index: 2;
}
.mobile_top_header,
.mobile_left_burger,
.mobile_right_fullscreen,
.play_buttons_mobile {
  display: none;
}

.please_change_root {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  z-index: 123111;
}
.please_change_header {
  width: 100%;
  height: 30%;
  text-align: center;
  font-size: 4.8vw;
  line-height: 12;
  font-weight: 600;
}
.please_change_gif {
  width: 100%;
  height: 30%;
}
.rotate_gif {
  width: 50%;
  height: 80%;
  margin-left: 25%;
}
.please_change_info {
  width: 100%;
  height: 40%;
  text-align: center;
  font-size: 3vw;
}
.mobile_drop_root {
  position: absolute;
  width: 9.3%;
  top: 21%;
  z-index: 2;
}
.mobile_drop_list {
  margin-left: 0;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.72);
}
.mobile_drop_item {
  padding: 3px 3px;
  background-color: rgba(255, 255, 255, 0.66);
  color: black;
  text-align: center;
  margin-bottom: 9px;
  border-radius: 3px;
  z-index: 12299;
  font-size: 0.9rem;
}

.pulse_for_betzone {
  position: absolute;
  width: 11%;
  height: 10%;
  top: 47.5%;
  left: 65%;
  pointer-events: none;
  transform: scale(1);
  animation: pulse-icon 2s infinite;
  border-radius: 50%;
}

@keyframes pulse-icon {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media all and (max-width: 1200px) {
  .App {
    background: url("../public/background_mobile.png");
    background-size: 100% 100vh;
    background-repeat: no-repeat;
  }
  .top_header {
    display: none;
  }
  .mobile_top_header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4.2rem;
    padding-top: 4.2%;
    color: lightgray;
    font-size: 0.72rem;
  }

  .pulse_for_betzone {
    top: 39.5%;
  }

  .mobile_left_burger {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 51px;
    height: 51px;
  }
  .mobile_right_fullscreen {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 51px;
    height: 51px;
  }
  .mobile_left_burger > img,
  .mobile_right_fullscreen > img {
    width: 100%;
    height: 100%;
  }

  .betzone_player {
    top: 40%;
  }
  .betzone_pc {
    top: 40%;
    left: 25%;
  }

  .bottom_chips {
    bottom: 0px;
    max-height: 100px;
    gap: 0.3rem;
  }
  .chip_container > img {
    max-width: 60px;
  }
  .chip_container_selected > img {
    width: 60px;
    height: 60px;
  }
  .chip_container > span {
    font-size: 1rem;
    color: white;
    top: -42px;
    pointer-events: none;
  }
  .chip_container_selected > span {
    font-size: 1rem;
    top: -43px;
  }
  .white_chip > span {
    color: black;
  }

  .betzone_chips {
    width: 39px;
    height: 39px;
    top: 35%;
  }
  .betzone_chips_pc {
    width: 39px;
    height: 39px;
    top: 35%;
  }
  .betzone_chips > div,
  .betzone_chips_pc > div {
    width: 39px;
    font-size: 0.6rem;
    top: -33px;
  }
  .bottom_buttons {
    display: none;
  }
  .play_buttons_mobile {
    display: flex;
    justify-content: space-between;
  }
  .left_play_button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 6%;
  }
  .right_play_button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 6%;
  }
  .button_undo,
  .button_throw,
  .button_double,
  .button_clear {
    width: 54px;
    height: 54px;
    background: url("../public/buttons/undo.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
    cursor: pointer;
  }
  .button_throw {
    background: url("../public/buttons/throw.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_double {
    background: url("../public/buttons/double.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_clear {
    background: url("../public/buttons/clear.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_undo:hover {
    background: url("../public/buttons/undo_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_throw:hover {
    background: url("../public/buttons/throw_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_double:hover {
    background: url("../public/buttons/double_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_clear:hover {
    background: url("../public/buttons/clear_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
}
